<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('extra_payment')"
				        :isFilter="false"/>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('extra_payment')"
				              :isFilter="false"/>
			</template>
			<div class="row">
				<div class="col-12 col-md-12 col-xl-12 col-xxl-12 mt-4">
                    <div class="row">
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('name') }}</label>
                                <div>{{ data.name }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('surname') }}</label>
                                <div>{{ data.surname }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('total_weekly_hours') }}</label>
                                <div>{{ data.extra_payment.total_hours }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('undergraduate_hours') }}</label>
                                <div>{{ data.extra_payment.undergraduate_hours }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('graduate_hours') }}</label>
                                <div>{{ data.extra_payment.graduate_hours }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('position') }}</label>
                                <div>{{ data.extra_payment.position }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('from_position') }}</label>
                                <div>{{ data.extra_payment.from_position }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('doctorate_thesis') }}</label>
                                <div>{{ data.extra_payment.doctorate_thesis }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('graduate_project') }}</label>
                                <div>{{ data.extra_payment.graduate_project }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('undergraduate_project') }}</label>
                                <div>{{ data.extra_payment.undergraduate_project }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('total_course_hours_load') }}</label>
                                <div>{{ data.extra_payment.total }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('overflow_expected_load') }}</label>
                                <div>{{ data.extra_payment.overflow }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('extra_course_load_to_pay') }}</label>
                                <div>{{ data.extra_payment.extra }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('ktys') }}</label>
                                <div>{{ data.extra_payment.factor }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('total_extra_course_fee') }}</label>
                                <div>{{ data.extra_payment.fee | formatNumber}}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('monthly_fee') }}</label>
                                <div>{{ data.extra_payment.monthly_fee | formatNumber}}</div>
                            </div>
                        </b-col>
                    </div>
				</div>
			</div>
		</app-layout>
	</div>
</template>
<script>
	// Template
	import AppLayout from "@/layouts/AppLayout";
	import Header from "@/layouts/AppLayout/Header";
	import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

	// Components
	import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
	import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";

	import {ValidationObserver, ValidationProvider} from "vee-validate";
    // Services
    import StaffService from "@/services/StaffService";

	export default {
		components: {
			AppLayout,
			Header,
			HeaderMobile,

			ValidationProvider,
			ValidationObserver,
		},
		data() {
			return {
				data: null,
			}
		},
		metaInfo() {
			return {
				title: this.$t("extra_payment")
			}
		},
		methods: {
			getData() {
				StaffService.getExtraPayment()
               .then(response => {
                   this.data = response.data.data
               })
			}
		},
		created() {
			this.getData()
		}
	}
</script>
